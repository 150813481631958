import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[12];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  Curra's Grill is one of the most recognized restaurants in
                  local publications. It was voted "Best Mexican Seafood" by the
                  Austin-American Statesman and "One of the Best Mexican
                  Restaurants" by Texas Monthly The food definately lived up to
                  the hype. This spot is perfect if you are looking for a
                  super-chill environment with a friendly staff.
                </p>
                <p>
                  An argument could be made that their "Tacos Al Pastor" is the
                  best on the block. It is a pork cooked on a rotisserie served
                  with cilantro, onions, pineapple & avocado... amazing. The
                  last two times we went, both of us ordered these same fish
                  tacos, which are tilapia fried strips with mango pico de gallo
                  and chipotle cream sauce.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
